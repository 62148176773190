/*
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-22 09:31:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-21 17:20:24
 */
import {
  post,
  get
} from '@/utils/request'
const path = '/train'
const API = {
  // 题库分组
  questionBankGroupDetail: path + '/questionBankGroup/findById', // 题库分组--详情
  questionBankGroupTreeList: path + '/questionBankGroup/getQuestionBankGroupTree', // 题库分组--树型题库分组
  questionBankGroupAdd: path + '/questionBankGroup/add', // 题库分组--新增
  questionBankGroupEdit: path + '/questionBankGroup/edit', // 题库分组--修改
  questionBankGroupDelete: path + '/questionBankGroup/remove', // 题库分组--删除
  // 题目管理
  topicManagePageList: path + '/questionInfo/findByPage', // 题目管理--列表
  topicManageAdd: path + '/questionInfo/add', // 题目管理--新增
  topicManageManyAdd: path + '/questionInfo/batchAdd', // 题目管理--批量新增
  topicManageEdit: path + '/questionInfo/edit', // 题目管理--修改
  topicManageDelete: path + '/questionInfo/remove', // 题目管理--删除
  topicManageDetail: path + '/questionInfo/findById', // 题目管理--详情
  // 题库管理
  questBankManagePageList: path + '/questionBank/findByList', // 题库管理--列表
  questBankManageAdd: path + '/questionBank/add', // 题库管理--新增
  questBankManageEdit: path + '/questionBank/edit', // 题库管理--修改
  questBankManageDelete: path + '/questionBank/remove', // 题库管理--删除
  // 考试安排
  addExamInfo: path + '/examInfo/add', // 考试安排-新增
  addExamfindById: path + '/examInfo/findById', // 考试安排-详情
  editExamfindById: path + '/examInfo/edit', // 考试安排-编辑
  // 试卷管理
  getPaperList: path + '/testPaperInfo/findByPage', // 试卷分页
  getPaperAdd: path + '/testPaperInfo/add', // 试卷新增
  getPaperEdit: path + '/testPaperInfo/edit', // 试卷（名称、描述）编辑
  getPaperDetail: path + '/testPaperInfo/findById', // 试卷详情
  getPaperContentEdit: path + '/testPaperInfo/saveOrUpdate', // 试卷内容编辑
  getPaperPublishOrStop: path + '/testPaperInfo/publishOrStop', // 发布、停用试卷
  getPaperManyDelete: path + '/testPaperInfo/remove', // 批量删除
  getPaperStatusNumber: path + '/testPaperInfo/statisticsStatusNum', // 获取试卷各个状态的试卷数量
  // 考试安排
  getExamArrangeList: path + '/examInfo/findByPage', // 考试安排--列表
  getArrangeStatusNum: path + '/examInfo/statisticsStatusNum', // 考试安排--列表（各个状态的数量）
  getExamArrangeAdd: path + '/examInfo/add', // 考试安排--新增
  getExamArrangeEdit: path + '/examInfo/edit', // 考试安排--编辑
  getExamArrangeDelete: path + '/examInfo/remove', // 考试安排--删除
  getExamArrangeDetail: path + '/examInfo/findById', // 考试安排--详情
  getExamStudentList: path + '/examInfo/testerByPage', // 考生分页
  getExamGrading: path + '/examInfo/correctExam', // 批阅试卷
  getStudentExamDetail: path + '/examInfo/getExamineeDetails', // 员工考试详情
  getImmediateExam: path + '/examInfo/beginExam', // 立即开考
  getFinishExam: path + '/examInfo/endExam', // 结束考试
  getExamRecord: path + '/examInfo/examRecordByPage', // 考试记录
  getExamPreview: path + '/examInfo/previewExamQuestion', // 预览试卷
  getExamRecordExport: path + '/examInfo/examRecordExport', // 考试记录导出
  getExamTopicAnalyse: path + '/examInfo/examAnalyzeByPage', // 考题分析
  getExamTopicAnalyseExport: path + '/examInfo/examAnalyzeExport', // 考题分析导出
  getTellDutyUser: path + '/examInfo/noticeDirector', // 通知负责人
  // 员工端接口  ---------------------------
  getPersonnalExamList: path + '/employeeExam/personnelExam', // 员工端-个人考试列表
  getClickStartExam: path + '/employeeExam/paperDetail', // 员工端-点击开始考试
  getEmployeeExamDetails: path + '/employeeExam/getEmployeeExamDetails', // 员工端-已考(未批阅、已批阅)考试详情
  getExamResultMessage: path + '/employeeExam/examResultMessage', // 员工端-考试结果通知
  getSubmitPaper: path + '/employeeExam/submitPaper', // 员工端-考试结束-提交试卷
  getExamRelativePaperQuery: path + '/employeeExam/personnelPaper', // 员工端-考试相关试卷查询
  getExamFinishDetail: path + '/employeeExam/getApprovalResult', // 交卷后--详情
  getStartExam: path + '/employeeExam/startExam', // 员工开始答题
  getUserExamDetailInfo: path + '/employeeExam/getEmployeeExamInfo' // 获取员工考试信息

}

export const questionBankGroupDetailApi = (data) => get(API.questionBankGroupDetail, data)// 题库分组--详情
export const questionBankGroupTreeListApi = (data) => post(API.questionBankGroupTreeList, data)// 题库分组--树型题库分组
export const questionBankGroupAddApi = (data) => post(API.questionBankGroupAdd, data)// 题库分组--新增
export const questionBankGroupEditApi = (data) => post(API.questionBankGroupEdit, data)// 题库分组--修改
export const questionBankGroupDeleteApi = (data) => post(API.questionBankGroupDelete, data)// 题库分组--删除
// 题目管理
export const topicManagePageListApi = (data) => post(API.topicManagePageList, data)// 题目管理--列表
export const topicManageAddApi = (data) => post(API.topicManageAdd, data)// 题目管理--新增
export const topicManageManyAddApi = (data) => post(API.topicManageManyAdd, data)// 题目管理--批量新增
export const topicManageEditApi = (data) => post(API.topicManageEdit, data)// 题目管理--修改
export const topicManageDeleteApi = (data) => post(API.topicManageDelete, data)// 题目管理--删除
export const topicManageDetailApi = (data) => get(API.topicManageDetail, data)// 题目管理--详情
// 题库管理
export const questBankManagePageListApi = (data) => post(API.questBankManagePageList, data)// 题库管理--列表
export const questBankManageAddApi = (data) => post(API.questBankManageAdd, data)// 题库管理--新增
export const questBankManageEditApi = (data) => post(API.questBankManageEdit, data)// 题库管理--修改
export const questBankManageDeleteApi = (data) => post(API.questBankManageDelete, data)// 题库管理--删除
// 考试安排
export const addExamInfoApi = (data) => post(API.addExamInfo, data)// 考试安排-新增
export const addExamfindByIdApi = (data) => get(API.addExamfindById, data)// 考试安排-详情
export const editExamfindByIdApi = (data) => post(API.editExamfindById, data)// 考试安排-编辑
// 试卷
export const getPaperListApi = (data) => post(API.getPaperList, data)// 试卷分页
export const getPaperAddApi = (data) => post(API.getPaperAdd, data)// 试卷新增
export const getPaperEditApi = (data) => post(API.getPaperEdit, data)// 试卷（名称、描述）编辑
export const getPaperDetailApi = (data) => get(API.getPaperDetail, data)// 试卷详情
export const getPaperContentEditApi = (data) => post(API.getPaperContentEdit, data)// 试卷内容编辑
export const getPaperPublishOrStopApi = (data) => post(API.getPaperPublishOrStop, data)// 发布、停用试卷
export const getPaperManyDeleteApi = (data) => post(API.getPaperManyDelete, data)// 批量删除
export const getPaperStatusNumberApi = (data) => post(API.getPaperStatusNumber, data)// 获取试卷各个状态的试卷数量
// 考试安排
export const getExamArrangeListApi = (data) => post(API.getExamArrangeList, data)// 考试安排--列表
export const getArrangeStatusNumApi = (data) => post(API.getArrangeStatusNum, data)// 考试安排--列表（各个状态的数量）
export const getExamArrangeAddApi = (data) => post(API.getExamArrangeAdd, data)// 考试安排--新增
export const getExamArrangeEditApi = (data) => post(API.getExamArrangeEdit, data)// 考试安排--编辑
export const getExamArrangeDeleteApi = (data) => post(API.getExamArrangeDelete, data)// 考试安排--删除
export const getExamArrangeDetailApi = (data) => get(API.getExamArrangeDetail, data)// 考试安排--详情
export const getExamStudentListApi = (data) => post(API.getExamStudentList, data) // 考生分页
export const getExamGradingApi = (data) => post(API.getExamGrading, data) // 批阅试卷
export const getStudentExamDetailApi = (data) => get(API.getStudentExamDetail, data)// 员工考试详情
export const getImmediateExamApi = (data) => post(API.getImmediateExam, data) // 立即开考
export const getFinishExamApi = (data) => post(API.getFinishExam, data)// 结束考试
export const getExamRecordApi = (data) => post(API.getExamRecord, data) // 考试记录
export const getExamPreviewApi = (data) => get(API.getExamPreview, data) // 考试试卷预览
export const getExamRecordExportApi = (data) => post(API.getExamRecordExport, data, {
  responseType: 'blob'
})// 考试记录导出
export const getExamTopicAnalyseApi = (data) => post(API.getExamTopicAnalyse, data) // 考题分析
export const getExamTopicAnalyseExportApi = (data) => post(API.getExamTopicAnalyseExport, data, {
  responseType: 'blob'
}) // 考题分析导出
export const getTellDutyUserApi = (data) => post(API.getTellDutyUser, data) // 通知负责人
// 员工端接口  ---------------------------
export const getPersonnalExamListApi = (data) => get(API.getPersonnalExamList, data) // 员工端-个人考试列表
export const getClickStartExamApi = (data) => get(API.getClickStartExam, data) // 员工端-点击开始考试
export const getEmployeeExamDetailsApi = (data) => get(API.getEmployeeExamDetails, data)// 员工端-已考(未批阅、已批阅)考试详情
export const getExamResultMessageApi = (data) => get(API.getExamResultMessage, data) // 员工端-考试结果通知
export const getSubmitPaperApi = (data) => post(API.getSubmitPaper, data) // 员工端-考试结束-提交试卷
export const getExamRelativePaperQueryApi = (data) => get(API.getExamRelativePaperQuery, data)// 员工端-考试相关试卷查询
export const getExamFinishDetailApi = (data) => get(API.getExamFinishDetail, data)// 交卷后--详情
export const getStartExamApi = (data) => get(API.getStartExam, data)// 交卷后--详情
export const getUserExamDetailInfoApi = (data) => get(API.getUserExamDetailInfo, data)// // 获取员工考试信息--详情
